@import '../../../assets//css/base.scss';

.manage-tags {
  &-modal {
    &-description {
      color: var(--greyscale_56);
      font-family: Noto Sans;
      font-size: toRem(16px);
      font-weight: 400;
      line-height: 24px;
    }

    .modal-dialog.modal-md.modal-dialog-centered {
      .modal-content {
        .modal-header {
          padding-top: 0;
          padding-bottom: 4px !important;
          justify-content: space-between;
        }
        .manage-tags-toast {
          top: 55px !important;
        }

        .modal-footer {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
        }
      }
    }

    &-system-tags-title,
    &-custom-tags-title {
      font-size: toRem(20px);
      font-weight: 600;
      line-height: toRem(28px); /* 140% */
    }

    &-system-tags-description,
    &-custom-tags-description {
      color: var(--greyscale_56);
      font-size: toRem(16px);
      font-weight: 400;
      line-height: toRem(24px); /* 150% */
    }
  }

  &-button-group {
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &-button-wrapper {
    padding: auto 20px;
    display: block;
  }

  &-form {
    .tag-input-wrapper {
      width: 94%;

      .tag-input {
        &.read-only {
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--grayscale_96);

          &:focus,
          &:focus-visible,
          &:hover,
          &:active {
            border: 1.5px solid var(--greyscale_88);
            outline: 0;
          }
        }
      }
    }

    .tag-input-full-wrapper {
      width: 100%;

      .inline-message-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .error {
          &-icon {
            margin: 4px 4px 0 20px;
            color: var(--greyscale_56);

            &.hidden {
              display: none;
            }
          }

          &-inline-message {
            color: var(--greyscale_56);
            font-size: toRem(12px);
            font-weight: 500;
            line-height: toRem(16px);
            margin-top: 4px;
          }
        }
      }

      .error-input {
        border-color: var(--error_48) !important;
        color: var(--error_48) !important;
        background-color: var(--primary_96);
      }
    }

    .delete-btn {
      &-wrapper {
        width: 6%;
      }
      &-icon {
        cursor: pointer;

        &.read-only {
          color: var(--greyscale_56);
          cursor: default;
        }
      }
    }
  }

  &-input {
    display: flex;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    width: 100%;

    &-char-limit {
      display: flex;
      justify-content: flex-end;
      color: var(--greyscale_56);
      font-size: toRem(12px);
      font-weight: 500;
      line-height: 16px;
      margin-right: 20px;
      margin-top: 4px;
    }

    &:active,
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_40);
      outline: 0;
    }
  }

  &-save-btn {
    font-size: toRem(14px);
    line-height: 20px;
    font-weight: 600;
    height: 44px;

    &:hover {
      background-color: var(--primary_24);
    }

    &.disabled {
      color: var(--grayscale_32);
    }
  }

  &-cancel-btn.btn.btn-outline-secondary {
    font-size: toRem(14px);
    line-height: 20px;
    font-weight: 600;
    width: 100%;
    height: 44px;
    color: var(--primary_40);
    border-color: var(--primary_40);

    &:focus {
      border-color: var(--primary_40);
      background-color: var(--brand_white);
    }

    &:hover {
      background-color: var(--primary_96);
    }
  }
}

@import '../../../assets/css/_utilities.scss';

.device-dashboard {
  .live-view-no-license-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: var(--grayscale_16);
    margin-top: 24px;
    border-radius: 4px;
    height: 320px;
    width: 100%;

    .no-license-notice {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      gap: 8px;

      .no-license-icon {
      }

      .no-license-text {
        color: #7d8ba1;
        font-size: toRem(16px);
        font-weight: 500;
        line-height: toRem(20px);
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }
}

.device-add-btn-icon {
  margin-right: 11px;
}

.settings-block {
  margin-bottom: 16px;

  .info-update {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .info-firmware {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .firmware-update-section {
    margin-top: -12px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .select-field-wrap {
    margin-top: 0;
    margin-bottom: 0;

    .form-select {
      margin-bottom: 0;
    }
  }

  .settings-block-title {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(20px);
    line-height: 28px;
    color: var(--greyscale_08);
    margin-top: 32px;
  }
}

.alert.alert-danger {
  background-color: var(--error_96);
  border-color: transparent;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;

  .alert-heading {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(16px);
    line-height: 20px;
    color: var(--error_24);
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--error_24);
    margin-bottom: 0;
  }

  .btn-close {
    height: 10px;
    width: 10px;
  }
}

.btn.btn-primary.attach-license-btn.disabled {
  padding: 10px 16px;
  background-color: var(--grayscale_96);
  outline: 0;
  border: 0;
  color: var(--greyscale_56);
  border: 2px solid var(--greyscale_88);
}

.list-wrapper {
  margin-top: 56px;
}

.filter-wrapper {
  display: flex;
  justify-content: space-between;
}

.search-container {
  display: flex;
  text-align: center;
  align-items: center;
  width: 424px;
  & .search-icon {
    position: absolute;
    z-index: 9;
    margin: 12px 8px 12px 16px;
  }
  & .search-input {
    display: flex;
    padding: 12px 16px 12px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    color: var(--greyscale-40);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    width: 100%;
    &:hover {
      background-color: var(--brand_white) !important;
      color: var(--greyscale_40) !important;
      border-color: var(--primary_48);
    }
    &:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: var(--brand_primary); // ToDo: Need to Fix
      outline: 0;
    }
  }
}

.device-status-selector {
  &-toggle {
    &.dropdown-toggle {
      display: inline-flex;
      height: 44px;
      padding: 8px 16px;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--greyscale_08);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border: none;
      &.btn-outline-secondary {
        &.show {
          background-color: var(--grayscale_96) !important;
          color: var(--greyscale_08) !important;
          border: none;
        }
      }

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: var(--grayscale_96) !important;
        color: var(--greyscale_08) !important;
        border: none;
      }
    }
  }

  &-menu {
    &.dropdown-menu {
      width: 200px;
      padding: 8px 0px;
      align-items: flex-start;
      margin-top: 8px;
      margin-left: -87px;
      &.show {
        border-radius: 12px;
        background: var(--brand_white);
        box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
          0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);
      }

      & .dropdown-item {
        display: flex;
        padding: 8px 12px !important;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;
        display: flex;
        &.active {
          color: var(--greyscale_08);
          background-color: var(--brand_white);
          font-weight: 700;
        }

        &:first-child {
          color: var(--primary_40);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
        & .checkbox {
          width: 16px;
          height: 16px;
          accent-color: var(--primary_40);
        }
      }
    }
  }

  .device-status-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }
  }
}
.discovered-devices-container {
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Warning-Warning-96, #fcf8ed);
  color: var(--warning_48);

  .discovered-device-message {
    color: var(--Black, #000);
    text-align: center;
    /* Paragraph/16px/Semibold */
    font-family: 'Noto Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    margin-left: 8px;
  }
  .pending-count {
    color: var(--Warning-Warning-24, #734f07);
    /* Paragraph/12px/Semibold */
    font-family: 'Noto Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
}
.show-variable-list-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.accordion {
  padding-top: 20px;

  & .accordion-item {
    display: flex;
    width: 1110px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: var(--grayscale_96);
    width: 100%;
    margin-bottom: 12px;
    padding: 16px 20px;
    gap: 8px;
    & .image-wrapper {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 4px;
      background: var(--brand_white);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      & .device-icon {
        width: 42px;
        height: 28px;
      }
    }
    & .accordion-header {
      width: 100%;
    }
    & .accordion-collapse {
      width: 100%;
    }
    & .accordion-button {
      padding: 0px;
    }
    & .radio-wrapper{
      width: -webkit-fill-available;
      padding: 4px 0px;
    }
    & .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      & .header-section-1 {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      & .header-section-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      & .firmware-update {
        display: flex;
        padding: 2px 12px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 8px;
        border: 2px solid rgba(var(--greyscale-custom4), 0.1);
        background: var(--error_96);
        margin-left: 12px;
        & .update-text {
          color: var(--error_24);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
      }
    }
    & .accordion-body {
      padding: 0px;
    }
    & .camera {
      display: flex;
      padding: 0px 32px 0px 8px;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--brand_white);
      box-shadow: 0px 4px 4px 0px rgba(var(--greyscale-custom1), 0.12);
      width: 100%;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
      & .try-again-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--brand_white);
        background-color: var(--grayscale_16);
        border: 1.5px solid var(--greyscale_56);
        font-size: toRem(12px);
      }
      & .camera-details {
        display: flex;
        padding: 16px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        flex: 1 0 0;
        align-self: stretch;
        overflow-x: auto;

        & .camera-name {
          color: var(--greyscale_08);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }
        & .detail-table {
          display: flex;
          align-items: flex-start;
          gap: 48px;
          align-self: stretch;
          & .table-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
          }
          & .table-header {
            color: var(--greyscale_40);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
            display: flex;
          }
          & .license {
            width: 241px;
          }
          & .attach-license {
            color: var(--primary_40);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 142.857% */
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          & .table-info {
            color: var(--greyscale_08);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: start;
          }
          & .width-100 {
            width: 100px;
            max-width: 100px;
          }
          & .width-120 {
            width: 120px;
            max-width: 120px;
          }
          & .width-150 {
            width: 150px;
            max-width: 150px;
          }
          & .width-200 {
            width: 200px;
            max-width: 200px;
          }
          & .feature-check-circle {
            color: var(--success_24);
            background-color: var(--brand_white);
            font-size: toRem(18px);
            margin-left: 8px;
          }
          & .status-indicator {
            margin-right: 0.375rem;
            height: 0.625rem !important;
            width: 0.625rem !important;

            &.status {
              &-online {
                color: var(--success_48);
              }

              &-claiming {
                color: var(--primary_48);
              }

              &-entered {
                color: var(--warning_64);
              }

              &-offline {
                color: var(--error_112);
              }

              &-ready-to-claim {
                color: var(--greyscale_40);
              }
            }
          }
        }
      }
    }
  }
}

.no-devices-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: var(--greyscale_08);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-top: 146px;
  & .image-wrapper {
    display: flex;
    width: 60px;
    height: 60px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: var(--grayscale_96);
  }
}

.error-container {
  display: flex;
  padding: 0.75rem;
  background: var(--error_96);
  border-radius: 0.75rem;
  margin: 1.5rem 0;

  .info-icon-container {
    display: flex;

    svg path {
      stroke: var(--error_24);
    }
  }

  .error-body {
    display: flex;
    justify-content: space-between;
    line-height: 1.25rem;
    color: var(--error_24);
    width: 100%;

    .error-content {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 0 0.5rem;
    }

    .cross-icon-container {
      display: flex;
      cursor: pointer;

      svg path {
        stroke: var(--error_24);
      }
    }
  }
}

.setting-icon {
  cursor: pointer;
}
.setting-icon-disabled {
  color: var(--greyscale_56) !important;
  cursor: text;
}

.disable-icon {
  cursor: not-allowed;
}

.device-list-page-wrapper {
  height: calc(100vh - 260px);
  overflow-y: scroll;

  & .list-block {
    margin-top: 20px;
  }
}

.spinner-container-device-list {
  display: 'flex';
  justify-content: 'space-around';
  align-items: 'center';
  height: 100px;
  position: relative;
  height: calc(100vh - 208px);
}

.spinner-center-device-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner-size-device-list {
  height: 50px;
  width: 50px;
}

@import '../../../assets/css/base';

.create-notification {
  width: 100%;

  .steps-header-conatiner {
    width: 100%;

    .header-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left-section-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .back-btn-conatiner {
          cursor: pointer;
          margin-bottom: 5px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .back-text {
            cursor: pointer;
            margin-left: 5px;
            color: var(--primary_40);
            font-size: toRem(16px);
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .header-title {
          color: var(--brand_black);
          font-size: toRem(18px);
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .cross-btn {
        width: 24px;
        height: 24px;
      }
    }

    .textbox {
      margin-top: 15px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      color: var(--greyscale_40);
      text-align: left;
      border-radius: 12px;
      border: 1.5px solid var(--greyscale_88);
      background: var(--brand_white);

      &.serach-text {
        color: var(--greyscale_08);
      }
    }
  }

  .step-indicator-conatiner {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .step-indicator {
      border-radius: 16px;
      background: var(--primary_88);
      height: 4px;
      width: 65px;

      &.custmer-user {
        width: 80px;
      }

      &.selected {
        background: var(--primary_40);
      }
    }
  }

  .event-type-step-one {
    margin-top: 20px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .system-custom-event-conatiner {
      margin-top: 15px;
      width: 100%;
      height: auto;
      padding: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        pointer-events: none;
      }

      .event-icon {
        width: 20px;
        height: 20px;
      }

      .title-discription-conatiner {
        margin-left: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .event-title {
          margin-top: -15px;
          color: var(--text_color);
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .event-discription {
          color: var(--greyscale_40);
          font-size: toRem(12px);
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }

      .input-radio-events {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin-top: 2px;
      }
    }
  }

  .trigger-type-step-two {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .step-title-desc {
      color: var(--greyscale_40);
      font-size: toRem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .trigger-event-conatiner {
      margin-top: 15px;
      width: 100%;
      height: auto;
      padding: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        pointer-events: none;
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .event-icon {
          width: 20px;
          height: 20px;
        }

        .title-discription-conatiner {
          margin-left: 5px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .event-title {
            margin-top: -15px;
            color: var(--text_color);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }

          .event-discription {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            width: 350px;
          }
        }
      }

      .input-check-events {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

  .devices-step-three {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .device-selection-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .step-title-desc {
        color: var(--greyscale_40);
        font-size: toRem(12px);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .step-title-desc-section {
        color: var(--text_color);
        font-size: toRem(16px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      .count-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .count-text {
          color: var(--primary_40);
          text-align: right;
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        .selected-text {
          margin-left: 2px;
          color: var(--greyscale_40);
          text-align: right;
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    .location-main {
      width: 100%;
      height: calc(100vh - 85vh);
      overflow: auto;
      margin-top: 5px;
      // &::-webkit-scrollbar {
      //     display: none !important;
      // }

      .location-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;

        .location-name {
          color: var(--brand_black);
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        .filed-radio {
          height: 20px;
          width: 20px;
        }
      }
    }
    .location-main-searchbox{
      height: auto !important;
    }
    .search-input-container {
      position: relative;
      display: flex;
      align-items: center;
    }
    
    .search-icon {
      position: absolute;
      left: 10px; /* Adjust as needed */
      pointer-events: none; /* Prevents the icon from blocking input */
    }
    
    .search-input {
      padding-left: 30px; /* Make space for the icon */
    }

    .search-input::placeholder {
      opacity: 1;
    }  
  }

  .card-container-notification{
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px;
  }

  .card-notification-custom{
    max-width: 235px;
    .card-notification-body{
      background: var(--greyscale_97);
    }
    .card-notification-custom-heading{
      font-size: toRem(14px);
      line-height: 20px;
      color: var(--text_color);
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      margin:auto;
    }
    .card-notification-custom-heading-2{
      font-size: toRem(12px);
      line-height: 16px;
      color: var(--text_color);
      font-weight: 500;
    }
    .input-notification{
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }
  }

  .sheduled-step-four {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .sheduled-selection-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .step-title-desc {
        color: var(--greyscale_40);
        font-size: toRem(12px);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .inner-container {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 180px;
      align-items: center;
      justify-content: space-between;

      .sheduled {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: var(--grayscale_96);

        .img-icon-sheduled {
          height: 32px;
          width: 32px;
        }
      }

      .step-add-data {
        width: 300px;
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
      }

      .add-btn {
        cursor: pointer;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: var(--primary_40);

        .img-icon-sheduled {
          height: 22px;
          width: 22px;
        }
      }
    }

    .sheduled-parent-container {
      margin-top: 10px;

      .list-sheduled {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        gap: 12px;
        background: var(--grayscale_96);
        border-radius: 12px;
        margin-top: 10px;
        padding-top: 5px;
        padding-bottom: 8px;

        .time-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &.days-block {
            margin-top: -10px;
          }

          .label-title {
            margin-left: 15px;
            font-style: normal;
            font-size: toRem(20px);
            line-height: 20px;
            display: flex;
            align-items: center;
            color: var(--greyscale_56);
            margin-top: 5px;

            &.selected {
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              color: var(--brand_black);
            }

            @media screen and (max-width: 767px) {
              font-size: toRem(12px);
            }
          }

          .days-selected {
            margin-left: 15px;
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: var(--greyscale_40);
          }

          .edit-text {
            margin-right: 8px;
            cursor: pointer;
            color: var(--primary_40);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 600;
            line-height: 20px;

            &.non-edit {
              pointer-events: none;
              font-weight: 500;
            }
          }

          .toggle-checkbox {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
          }
        }
      }
    }

    .add-sheduled {
      cursor: pointer;
      margin-top: 10px;
      width: 100%;
      border-radius: 12px;
      border: 1.5px dashed var(--greyscale_80);
      background: var(--grayscale_96);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 88px;

      .add-text {
        color: var(--greyscale_08);
        text-align: center;
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .users-step-five {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .user-selection-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .step-title-desc {
        color: var(--greyscale_40);
        font-size: toRem(12px);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .count-layout {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .count-text {
          color: var(--primary_40);
          text-align: right;
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        .selected-text {
          margin-left: 2px;
          color: var(--greyscale_40);
          text-align: right;
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }

    .serach-box {
      margin-top: 10px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      padding: 10px 20px;
      width: 100%;
      height: 50px;
      background: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      align-items: center;

      .textbox {
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(16px);
        line-height: 20px;
        color: var(--greyscale_40);
        margin-left: 1px;
        text-align: left;

        &.serach-text {
          color: var(--greyscale_08);
        }
      }
    }

    .user-conatiner {
      margin-top: 15px;
      width: 100%;
      height: auto;
      padding: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        pointer-events: none;
      }

      .event-title {
        color: var(--text_color);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
      }

      .input-check-events {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

  .notification-type-step-six {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .step-title-desc {
      color: var(--greyscale_40);
      font-size: toRem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .noti-type-conatiner {
      margin-top: 15px;
      width: 100%;
      height: auto;
      padding: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        pointer-events: none;
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .event-icon {
          width: 20px;
          height: 20px;
        }

        .title-discription-conatiner {
          margin-left: 5px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .event-title {
            margin-top: -15px;
            color: var(--text_color);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }

          .event-discription {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      .input-check-events {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

  .notification-type-step-six {
    margin-top: 10px;

    .step-title {
      color: var(--brand_black);
      font-size: toRem(18px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }

    .step-title-desc {
      color: var(--greyscale_40);
      font-size: toRem(12px);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .noti-type-conatiner {
      margin-top: 15px;
      width: 100%;
      height: auto;
      padding: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &.disabled {
        pointer-events: none;
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        .event-icon {
          width: 20px;
          height: 20px;
        }

        .title-discription-conatiner {
          margin-left: 5px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .event-title {
            margin-top: -15px;
            color: var(--text_color);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }

          .event-discription {
            color: var(--greyscale_40);
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
      }

      .input-check-events {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-top: 2px;
      }
    }
  }

  .input-radio-events-trigger{
    width: 20px;
    height: 20px;
    appearance: none;
    border: 1.5px solid var(--hGreyscale_80);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  .input-radio-events-trigger:checked {
    border: 1.5px solid var(--brand_primary);
  }
  .input-radio-events-trigger:checked::before{
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--brand_primary);
    color: var(--brand_white);
  }
}
.create-notification .devices-step-three .location-main .location-list {
  input[type='radio'] {
    appearance: none;
    border: 1px solid var(--brand_primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input[type='radio']:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--brand_primary);
    color: var(--brand_white);
  }

  input[type='radio']:checked::after {
    position: relative;
    background: var(--brand_primary);
    color: var(--brand_white);
    border-radius: 50%;
  }
}

.create-notification .event-type-step-one .system-custom-event-conatiner {
  input[type='radio'] {
    width: 27px;
    appearance: none;
    border: 1px solid var(--brand_primary);
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    margin: 0;

    &:disabled {
      border: 2px solid var(--custom_color_disabled);
      opacity: 0.5;
    }
  }

  input[type='radio']:checked::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--brand_primary);
    color: var(--brand_white);
  }
}

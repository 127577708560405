@import '../../../../assets/css/base.scss';

.col {
  padding-left: 0;
  padding-right: 0;

  .license {
    &-block {
      .list-block-item {
        padding: 0px;
        margin: 0;
        background-color: transparent;
      }

      .card-body {
        margin: 0;
      }
    }

    &-features {
      list-style: none;
      border: 0;
      padding-left: 0;

      li {
        padding: 16px 4px;
        margin: 0 0 10px;
        border-radius: 12px;
        background-color: var(--grayscale_96);

        .container {
          .no-licenses {
            &-attached {
              display: flex;
              justify-content: space-around;
              text-align: center;
              font-size: toRem(14px);
              font-weight: 500;
              line-height: toRem(20px);
              text-transform: none;
            }

            &-available {
              font-size: toRem(12px);
              color: var(--greyscale_56);
              text-transform: none;
            }
          }
        }
      }

      .purchase-button-container {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
      }
    }

    &-action {
      color: var(--primary_40);
      text-align: right;
      cursor: pointer;
      font-weight: 500;
      font-size: toRem(16px);

      &:hover {
        text-decoration: underline;
      }
    }

    &-prop {
      color: var(--greyscale_56);
      font-size: toRem(12px);
      font-weight: 500;
      line-height: 1.15rem;

      &-value {
        display: inline-flex;
        color: var(--greyscale_08);
        font-size: toRem(16px);
        line-height: 1.15rem;
        align-items: center;

        .value-wrapper {
          margin-left: 6.5px;
        }
      }
    }
  }
}

.spacer {
  height: 10px;
  width: 100vw;
}

@import '../../../assets//css/base.scss';

.close-incident {
  &-date-container {
    width: 100%;

    .icon-tabler-calendar-event {
      margin-right: 10px;
      color: var(--greyscale_40);
      height: 20px;
    }

    .customized-datepicker {
      .rmdp-day-picker,
      .rmdp-month-picker,
      .rmdp-year-picker {
        .rmdp-day {
          span:hover {
            background-color: var(--primary_64);
          }

          &.rmdp-today {
            span {
              background-color: var(--primary_80);
              color: var(--text_color);
            }
          }

          &.rmdp-disabled {
            span:hover {
              background-color: var(--primary_64);
              color: var(--brand_white);
            }
          }

          &.rmdp-selected {
            span:not(.highlight) {
              background-color: var(--primary_40);
            }
          }
        }
      }
    }
  }

  &-time-container {
    .MuiTextField-root {
      width: 100%;
      border-color: var(--grayscale_96);

      .MuiOutlinedInput-root {
        border-radius: 10px;
        background-color: var(--grayscale_96);

        &.MuiInputBase-colorPrimary.MuiInputBase-formControl {
          &:hover {
            border-color: var(--primary_88);
          }

          &:focus,
          &:focus-visible,
          &:focus-within {
            border-color: var(--primary_40);
            outline: 0;
          }
        }

        .MuiOutlinedInput-input {
          color: var(--greyscale_08);
          text-align: center;
          font-size: toRem(24px);
          font-weight: 600;
          line-height: toRem(32px);
          padding-top: 10px;
          padding-bottom: 10px;

          &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: var(--primary_40);
            }
          }
        }

        .MuiSvgIcon-root {
          height: 20px;
          color: var(--greyscale_40);
        }
      }

      .MuiInputBase-adornedEnd.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-root.MuiOutlinedInput-root {
        border-radius: 12px;

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--primary_40);
          }
        }
      }
    }
  }

  &-note-container {
    border: 0;
    position: relative;
    
    .MuiTextField-root {
      width: 100%;
      --bs-border-width: 0;

      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline {
        border-radius: 12px;

        &.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: var(--primary_40);
          }
        }
      }
    }
    .custom-placeholder {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      top: 10px;
      line-height: 1rem !important;
      z-index: 9;
      overflow: hidden;
      white-space: nowrap;
      padding: 10px;
      margin-left: 6px;
      background-color: var(--brand_white);

      color: var(--greyscale_56);
      opacity: 1;
      font-size: toRem(16px);
      font-weight: 500;
      line-height: toRem(20px);

      .required {
        color: var(--error_64);
        margin-left: 4px;
        margin-top: -8px;
      }
    }
  }

  &-cal-input {
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    margin-right: 20px;
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    padding-left: 18px;

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-color: var(--primary_40);
      outline: 0;
    }
  }

  &-modal {
    .modal-header {
      padding-top: 0;
      padding-bottom: 0;
    }

    .modal-content {
      .close-incident-note-info {
        padding-left: 20px;
        padding-right: 20px;

        .note-alert {
          display: inline-flex;
          justify-content: space-between;
          align-items: center;

          .exclamation-icon {
            margin-top: 4px;
            margin-right: 4px;
            color: var(--greyscale_56);
          }
        }

        .small-text {
          color: var(--greyscale_56);
          font-size: toRem(12px);
          font-weight: 500;
          line-height: toRem(16px);
          margin-top: 4px;

          &.error {
            color: var(--error_48);
          }
        }
      }
    }

    .modal-footer {
      border: 0;
      width: 100%;
      display: block;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      .close-incident-button-wrapper {
        margin-left: 0;
        margin-right: 0;

        .close-incident {
          &-cancel-btn {
            display: flex;
            width: 100%;
            border-radius: 10px;
            border: 1.5px solid var(--primary_40);
            color: var(--primary_40);
            font-size: toRem(14px);
            font-weight: 600;
            line-height: toRem(20px);
            padding: 12px 16px;
            justify-content: space-around;
            align-items: center;
            gap: 8px;

            &:focus {
              border-radius: 12px;
              border: 2px solid var(--primary_24);
              color: var(--primary_40);
            }

            &:hover {
              border-radius: 12px;
              border-color: var(--primary_40);
              background: var(--primary_96);
              color: var(--primary_40);
            }
          }
        }
      }
    }

    &-description {
      color: var(--greyscale_40);
      font-size: toRem(16px);
      font-weight: 400;
      line-height: toRem(24px);
    }

    .close-incident-status {
      &-wrapper {
        display: block;
        flex-direction: column;

        .status {
          &-radio {
            color: var(--greyscale_80);
            margin-right: -5px;
            padding-right: 0;

            &.Mui-checked {
              color: var(--primary_40);
            }

            &-group {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex-wrap: wrap;
            }

            &-label,
            &-row {
              color: var(--greyscale_08) !important;
              display: flex;
              width: 100%;
              padding: 12px 16px;
              flex-direction: row;
              align-items: flex-start;
              gap: 12px;

              border-radius: 12px;
              background-color: var(--grayscale_96);
              margin-bottom: 8px;

              font-size: toRem(16px) !important;
              font-weight: 500;
              line-height: toRem(20px) !important;
            }
          }
        }

        .MuiFormControlLabel-root
          MuiFormControlLabel-labelPlacementEnd.status-radio-label {
          display: inline-flex;

          .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            width: 100%;
          }
        }
      }
    }
  }

  &-label {
    color: var(--brand_black);
    font-size: toRem(20px);
    font-weight: 600;
    line-height: toRem(28px);
  }

  &-sublabel {
    color: var(--greyscale_56);
    font-size: toRem(14px);
    font-weight: 400;
    line-height: toRem(20px);
  }
}

.MuiPickersPopper-root {
  .MuiPaper-root {
    .MuiPickersLayout-root {
      .MuiButtonBase-root.MuiButton-root.MuiButton-text {
        background: var(--primary_96);
        color: var(--primary_40);

        &:hover {
          background-color: var(--primary_88);
        }
      }
    }
  }
}

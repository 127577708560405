.range-field-container-input-slider{
    display: flex;
    gap: 10px;
    .range-slider-new {
        width:70%;   
        &.disabled {
          .range-slider-track {
            &.range-slider-track-0 {
              background-color: var(--hGreyscale_72);
            }
          }
          .range-slider-thumb{
            background-color: var(--hGreyscale_72);
          }
        }
        .range-slider-track {
          top: 15px;
          height: 8px;
          background: var(--greyscale_88);
          border-radius: 12px;
    
          &.range-slider-track-0 {
            background: var(--slider-red);
          }
    
          .range-slider-start-value {
            position: absolute;
            bottom: 18px;
          }
    
          .range-slider-last-value {
            position: absolute;
            right: 0;
            bottom: 18px;
          }
        }
    
        .org-range-slider-track {
          top: 12px;
          height: 8px;
          background: var(--progress_org_device);
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-left: -10px;
    
          &.org-range-slider-track-0 {
            /* color of the track before the thumb */
            background: var(--slider-red);
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-left: 10px;
          }
    
          .range-slider-start-value {
            position: absolute;
            bottom: 18px;
          }
    
          .range-slider-last-value {
            position: absolute;
            right: 0;
            bottom: 18px;
          }
        }
    
        .range-slider-thumb {
          top: 5px;
          width: 24px;
          height: 24px;
          background: var(--primary_40);
          border: 8px solid var(--brand_white);
          border-radius: 50%;
          box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.06),
            0px 2px 4px -1px rgba(var(--greyscale-custom1), 0.04);
    
          &:focus-visible {
            outline: unset;
          }
    
          .range-slider-thumb-value {
            position: absolute;
            bottom: 18px;
            left: -5px;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(12px);
            line-height: 16px;
            color: var(--slider-red);
          }
          
          .range-slider-thumb-value-default-bitrate {
            position: absolute;
            bottom: -24px;
            width: 75px;
            left: -5px;
            font-style: normal;
            font-weight: 500;
            font-size: toRem(12px);
            line-height: 16px;
            color: var(--slider-red);
          }
        }
        .range-slider-mark {
          color: var(--slider-red);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
          top: 45px;
          padding: 0px 5px;
        }
    }


    button{
      width: 36px;
      background: var(--slider-red);
      color:white;
      border: none;
      border-radius: 10px;
      font-size: 20px;
      font-weight: 500;
      &:disabled{
        background-color: var(--hGreyscale_88);
        color:var(--hGrayscale_32);
      }
    }
    .input-text-parent{
      position: relative;
      display: inline-block;
    }
    .parent-wiothoutlabel{
      margin-left: auto;
    }
    .suffix-slider{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: grey;
      pointer-events: none;
    }
    .suffix-input{
      padding-right: 30px;
      width: 100px;
    }
    .without-suffix{
      width: 65px;
      text-align: right;
    }
    .slider-input{
      border: 1.2px solid var(--hGreyscale_88);
      background-color: white;
      border-radius: 9px;
      padding: 5px 10px;
      &:focus-visible{
        outline: none;  
      }
    }
}

.range-field-label-slider {
  color: var(--text_color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 15px;
}
@import '../../../assets/css/base.scss';

.note-details {
  &-container {
    margin-top: 24px;

    .note-video-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: var(--grayscale_24);
      width: 100vw;
      z-index: 10;
      min-height: 460px;
      left: 0;

      .note-video-player {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .note-artifact-details-back-link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      &-icon {
        color: var(--primary_40);
      }

      &-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-weight: 600;
        line-height: toRem(20px);
        margin-left: 4px;
      }
    }

    .note-artifact-details-content {
      display: block;
      width: 100%;
      min-height: 460px;
      margin-top: 506px;
      margin-left: -10px;
      max-width: 1320px;
      position: absolute;
      color: var(--brand_black);

      &.no-video {
        margin-top: 20px;
      }

      &-narrow-width {
        width: 732px;
      }

      .note-artifact-details-file {
        color: var(--brand_black);
        font-size: toRem(24px);
        font-weight: 700;
        line-height: toRem(32px);
        text-align: left;

        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .note-artifact-details-info {
        text-align: left;
        color: var(--greyscale_56);
        font-size: toRem(14px);
        font-weight: 400;
        line-height: toRem(20px);

        &-date-time {
          color: var(--brand_black);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
        }

        &-date-time-title {
          color: var(--brand_black);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);
        }

        &-creator {
          margin-bottom: 8px;
        }

        &-file-title,
        &-comment-title {
          color: var(--brand_black);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);
        }
        &-description {
          padding: 1rem;
          color: var(--primary_08);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);
        }
      }

      .note-artifact-details-view-log {
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-title {
          color: var(--brand_black);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);
        }

        &-view-all-link {
          cursor: pointer;
          color: var(--primary_40);
          font-size: toRem(14px);
          font-weight: 600;
          line-height: toRem(20px);

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .note-artifact-details-logs {
        .view-log-detail {
          &-wrapper {
            display: flex;
            padding: 12px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 12px;
            background-color: var(--grayscale_96);
          }

          &-content {
            display: flex;
            justify-content: flex-start;
          }

          &-avatar {
            margin-right: 8px;
          }

          &-creator {
            text-align: left;

            &-name {
              color: var(--greyscale_08);
              font-size: toRem(16px);
              font-weight: 500;
              line-height: toRem(20px);
              margin-bottom: 2px;
            }

            &-date {
              color: var(--greyscale_40);
              font-size: toRem(12px);
              font-weight: 500;
              line-height: toRem(16px);
            }
          }
        }
      }
    }
  }
}

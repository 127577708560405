@import 'base';

/* Site Modal CSS */
.modal-dialog {
  .btn-close:focus,
  .btn-close:hover,
  .btn-close:active {
    color: var(--greyscale_56);
    box-shadow: unset;
    opacity: unset;
  }

  .modal-content {
    background: var(--brand_white);
    border-radius: 16px;
    padding: 32px;
    border: 0;

    @media (min-width: 641px) {
      min-width: 542px;
    }

    .modal-header {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      align-items: baseline;

      .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: toRem(20px);
        line-height: 28px;
        color: var(--brand_black);
        max-width: 400px;
        &.location-font {
          font-size: toRem(18px);
        }
        &.centered-title{
          width: 100%;
          text-align: center;
        }

        span {
          font-size: toRem(14px);
          line-height: 20px;
          font-weight: 500;
        }
      }

      .site-modal-close {
        cursor: pointer;
        color: var(--greyscale_40);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }

      .events-ai-site-modal-close {
        cursor: pointer;
        color: var(--brand_white);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }
    }

    .modal-body {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;

      .modal-form {
        .form-control {
          padding: 12px 16px;
          margin-bottom: 8px;
        }
        .form-control:focus {
          border-color: var(--brand_primary); // ToDo: Need to Fix
        }

        .error {
          margin-bottom: 8px;
        }
        .pac-container {
          z-index: 1100;
        }
      }

      .modal-footer {
        border-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .reset-password-customer-user-modal,
  .send-invitation-customer-user-modal {
    .modal-header {
      .modal-title {
        width: 100%;
        text-align: center;
      }
    }

    .modal-footer {
      border-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.edit-customer-user-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .status-info,
  .btn {
    font-size: toRem(14px);
    line-height: 20px;
    font-weight: 500;
  }
}

.edit-partner-user-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .status-info,
  .btn {
    font-size: toRem(14px);
    line-height: 20px;
    font-weight: 500;
  }
}

.reset-password-customer-user-modal,
.send-invitation-customer-user-modal {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
}


.pac-container {
  z-index: 1100;
}

.modal-backdrop {
  background-color: rgba(var(--greyscale-custom2), 0.8);
  --bs-backdrop-opacity: 1;
}

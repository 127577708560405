@import '../../../assets/css/variables.scss';

.event-all-category-scrollable-area {
  overflow-y: visible;
}

.iconset {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}

.grid-image-fetch {
  height: 100%;
  width: 100%;
  margin: 0;
  background-image:
    repeating-linear-gradient(var(--brand_white) 0 1px, transparent 1px 100%),
    repeating-linear-gradient(90deg, var(--brand_white) 0 1px, transparent 1px 100%);
  background-size: 71px 71px;
  opacity: 0.3;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  aspect-ratio: 16/9;
  background: var(--brand_black);
}

.region-select-main {
  display: inline-flex;
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  left: 0;
  justify-content: center;
}